<template>
  <div class="chart-wrapper">
    <canvas ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  props: {
    averageData: {
      type: Array,
      required: true,
    },
    userData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  computed: {
    formattedUserData() {
      return this.formatData(this.userData);
    },
    formattedAverageData() {
      return this.formatData(this.averageData);
    },
  },
  methods: {

    renderChart() {
    // Extract years from userData
    const years = this.userData.map(item => item.year);

    // Render the chart using Chart.js
    this.chart = new Chart(this.$refs.chart, {
      type: 'line',
      data: {
        labels: years,
        datasets: [
          {
            label: 'Average Data',
            data: this.formattedAverageData,
            fill: false,
            backgroundColor: 'rgba(0,0,0)', // Set background color for the dataset
            borderColor: 'rgba(0,0,0)', // Set border color for the dataset
            borderWidth: 2, // Set border width for the dataset
          },
          {
            label: 'User Data',
            data: this.formattedUserData,
            fill: false,
            backgroundColor: 'rgba(65	172	170)', // Set background color for the dataset
            borderColor: 'rgba(65	172	170)', // Set border color for the dataset
            borderWidth: 2, // Set border width for the dataset
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Profit Before Tax Margin', // Set chart title
          fontSize: 14, // Set font size for the chart title
          fontStyle: 'normal', // Set font style for the chart title
          fontColor: 'rgba(0, 0, 0, 1)', // Set font color for the chart title
          defaultFontFamily: Chart.defaults.global.defaultFontFamily = "'Poppins', 'sans-serif'"
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'rgba(0, 0, 0, 1)', // Set font color for the legend labels
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'X Axis Label', // Set x-axis label
              fontSize: 14, // Set font size for x-axis label
              fontColor: 'rgba(0, 0, 0, 1)', // Set font color for x-axis label
            },
            ticks: {
              fontColor: 'rgba(0, 0, 0, 1)', // Set font color for x-axis ticks
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Y Axis Label', // Set y-axis label
              fontSize: 14, // Set font size for y-axis label
              fontColor: 'rgba(0, 0, 0, 1)', // Set font color for y-axis label
            },
            ticks: {
              fontColor: 'rgba(0, 0, 0, 1)', // Set font color for y-axis ticks
            },
          },
        },
      },
    });
  },

    updateChart() {
      // Update the chart with new data
      this.chart.data.labels = this.userData.map(item => item.year);
      this.chart.data.datasets[0].data = this.formattedAverageData;
      this.chart.data.datasets[1].data = this.formattedUserData;
      this.chart.update();
    },
    formatData(data) {
      // Extract data for 'profitBeforeTaxMargin' from data array
      const formattedData = data.map(item => {
        return {
          x: item.year, // Assuming 'year' is the property name for year in the data
          y: item.profitBeforeTaxMargin, // Assuming 'profitBeforeTaxMargin' is the property name for the data value
        };
      });

      // Format data array for Chart.js
      return formattedData;
    },  
  },
  watch: {
    userData: {
      handler: 'updateChart',
      deep: true,
    },
    averageData: {
      handler: 'updateChart',
      deep: true,
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

canvas {
  background: #F0F2F4;
  border-radius: 10px;
  padding: 10px;
}
</style>
