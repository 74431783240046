<template>
<div id="dashpreformance" class="dashboard-questions bg-greyblue pb-100px">
    <div class="container"  v-if="loading == true">
        <div class="flex items-center px-25px py-25px bg-black text-white rounded">
            <div class="lg:w-3/12">
                <p class="text-med">Preformance Reviews</p>
            </div>
            <div class="lg:w-3/12 lg:ml-auto">
                    <select v-model="selectedData" class="dash-preformance__select">
                        <option v-for="data in dataSet" :value="data.value" :selected="1" :key="data.value">{{ data.text }}</option>
                    </select>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row items-center py-25px mt-25px">
            <div class="lg:w-half flex items-center bg-white rounded py-50px px-25px" style="min-height: 150px">
                <div class="lg:w-3/12">
                    <p v-if="(selectedData == 1)" class="text-large text-teal">{{resultID.sector.score}}</p>
                    <p v-else-if="(selectedData == 2)" class="text-large text-teal">{{resultID.global.score}}</p>
                </div>
                <div class="lg:w-4/12 lg:ml-auto">
                    <p class="text-large">Total score</p>
                </div>
            </div>
            <div class="lg:w-half flex flex-col ml-50px">
                <div class="flex items-center bg-white py-40px px-25px">
                    <p class="text-small text-teal py-5px">Your total score is above the current industry average of 513. Our statistics show that SME’s with an industry average of 600 or more, see a 15% increase in yearly revenue.</p>
                </div>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row items-center py-25px">
            <div v-if="(selectedData == 1)" class="lg:w-full flex items-center">
                <div v-for="(section) in resultID.sector.section" :key="section.id" class="lg:w-half lg:mr-25px bg-white py-50px px-25px overflow-hidden dash-preformance__card">
                    <router-link class="btn btn--arrow dashboard-preformance-btn bg-white px-2 py-2 mr-50px duration-300 text-darkgreen" :to="`/${section.name.toLowerCase()}-detailed`">
                        {{section.name}}
                    </router-link>
                    <div v-if="(section.name == 'Growth')">
                        <p class="text-small mt-100px pb-20px">This section focuses on your organisation’s ambition for growth and how this is being achieved in the short and long run.</p>
                    </div>
                    <div v-else-if="(section.name == 'Barriers')">
                        <p class="text-small mt-100px">This section focuses on your organisations ability to execute new projects, level of funding available, and skills required pre and post-delivery.</p>
                    </div>
                    <div v-else-if="(section.name == 'Leadership')">
                        <p class="text-small mt-100px">This section focuses on your organisation’s leadership and their ability and knowledge to drive success in your business.<br><br></p>
                    </div>
                    <div v-else-if="(section.name == 'Technology')">
                        <p class="text-small mt-100px">This section focuses on your organisation’s awareness and outlook on technology as well as the level of technological adoption you are currently at.</p>
                    </div>
                    <p class="text-large mt-50px mb-25px">Score: {{section.score}}</p>
                </div>
            </div>
            <div v-else-if="(selectedData == 2)" class="lg:w-full flex items-center">
                <div v-for="(section) in resultID.global.section" :key="section.id" class="lg:w-half lg:mr-25px bg-white py-50px px-25px overflow-hidden dash-preformance__card">
                    <router-link class="btn btn--arrow dashboard-preformance-btn bg-white px-2 py-2 mr-50px duration-300 text-darkgreen" :to="`/${section.name.toLowerCase()}-detailed`">
                        {{section.name}}
                    </router-link>
                    <div v-if="(section.name == 'Growth')">
                        <p class="text-small mt-100px pb-20px">This section focuses on your organisation’s ambition for growth and how this is being achieved in the short and long run.</p>
                    </div>
                    <div v-else-if="(section.name == 'Barriers')">
                        <p class="text-small mt-100px">This section focuses on your organisations ability to execute new projects, level of funding available, and skills required pre and post-delivery.</p>
                    </div>
                    <div v-else-if="(section.name == 'Leadership')">
                        <p class="text-small mt-100px">This section focuses on your organisation’s leadership and their ability and knowledge to drive success in your business.<br><br></p>
                    </div>
                    <div v-else-if="(section.name == 'Technology')">
                        <p class="text-small mt-100px">This section focuses on your organisation’s awareness and outlook on technology as well as the level of technological adoption you are currently at.</p>
                    </div>
                    <p class="text-large mt-50px mb-25px">Score: {{section.score}}</p>
                </div>
            </div>
        </div>
        <div class="flex">
            <router-link to="/preformancereview" class="btn btn--arrow pl-20px dash-preformance__detailed-btn rounded-full bg-black p-3 mr-50px duration-300 text-white">
                View detailed results
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import firebase from "firebase";
import store from "./../../store";
import { createPopper } from "@popperjs/core";
// import PreformanceDropdown from "@/views/dropdowns/PreformanceDropdown.vue";

export default {
    name: "DashPreformances",

    data() {
        return {
            multipleResults: '',
            companies: '',
            resultID: [],
            dropdownPopoverShow: false,
            selectedData: '1',
            dataSet: [
                { value: 1, text: 'Industry Comparison' },
                { value: 2, text: 'Global Comparison' },
            ],
            loading: false,
        };
    },

    created() {

        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/4'
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: companies
                }, {
                    data: multipleResults
                }, ]) => {
                    this.companies = companies;
                    this.multipleResults = multipleResults;
                    this.testingStuff();
                });
        });

    },

    async mounted () {
        // this.testingStuff();
    },

    methods: {
        testingStuff() {
            let companyInfo = this.companies;
            let results = companyInfo.results;
            let mainResult = results[0];

            let result_id = mainResult.id;

            firebase.auth().onAuthStateChanged(user => {
                store.dispatch("fetchUser", user);

                axios.defaults.headers = {
                    'Content-Type': 'application/json',
                    Authorization: user.za
                }

                let endpoints = [
                    `https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/results/${result_id}`,
                ];

                Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                    .then(([{
                        data: resultID
                    }]) => {
                        this.resultID = resultID;
                        this.loading = true;

                        // console.log(resultID.global)
                    });

            });

        },

    },
};
</script>
