<template>
<div>
    <div id="financepreformance" class="dashboard-questions bg-greyblue pb-70px">
        <div class="container" v-if="loading == true">
            <div class="flex items-center rounded">
                <div class="tab-buttons">
                    <button class="mr-10px bg-black text-white p-20px rounded" @click="activeTab = 'tab1'" :class="{ 'bg-white text-black active': activeTab === 'tab1' }">Productivity</button>
                    <button class="mr-10px bg-black text-white p-20px rounded" @click="activeTab = 'tab2'" :class="{ 'bg-white text-black active': activeTab === 'tab2' }">Profitability</button>
                    <button class="mr-10px bg-black text-white p-20px rounded" @click="activeTab = 'tab3'" :class="{ 'bg-white text-black active': activeTab === 'tab3' }">Liquidity</button>
                </div>
            </div>
            <div>
                <div class="flex flex-wrap items-center py-50px mt-50px rounded bg-white relative z-10" v-if="activeTab === 'tab1'">
                    <div class="w-full lg:w-half mb-12 xl:mb-0 px-4">
                        <LineChartRevenuePerEmployee :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                    <div class="w-full lg:w-half mb-12 xl:mb-0 px-4">
                        <LineChartProfitPerEmployee :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                    <div class="w-full xl:w-full mt-12 mb-12 xl:mb-0 px-4">
                        <LineChartAssetTurnover :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                </div>
                <div class="flex flex-wrap items-center py-50px mt-50px rounded bg-white relative z-10" v-if="activeTab === 'tab2'">
                    <div class="w-full lg:w-half mb-12 px-4">
                        <LineChartGrossMargin :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                    <div class="w-full lg:w-half mb-12 px-4">
                        <LineChartOperatingMargin :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                    <div class="w-full lg:w-half mb-12 px-4">
                        <LineChartProfitBeforeTaxMargin :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                    <div class="w-full lg:w-half mb-12 px-4">
                        <LineChartReturnOnCapitalReturned :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                </div>
                <div class="flex flex-wrap items-center py-50px mt-50px rounded bg-white relative z-10" v-if="activeTab === 'tab3'">
                    <div class="w-full lg:w-half mb-12 px-4">
                        <LineChartCashflow :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                    <div class="w-full lg:w-half mb-12 px-4">
                        <LineChartAcidTest :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                    </div>
                </div>
            </div>
            <span class="page-seperator mt-85px"></span>
        </div>
    </div>
    
</div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import axios from "axios";
import firebase from "firebase";
import store from "./../../store";
import FinanceDropdown from "@/views/dropdowns/FinanceDropdown.vue";
import LineChartProfitPerEmployee from "@/views/cards/LineChartProfitPerEmployee.vue";
import LineChartAssetTurnover from "@/views/cards/LineChartAssetTurnover.vue";
import LineChartCashflow from "@/views/cards/LineChartCashflow.vue";
import LineChartRevenuePerEmployee from "@/views/cards/LineChartRevenuePerEmployee.vue";
import LineChartGrossMargin from "@/views/cards/LineChartGrossMargin.vue";
import LineChartOperatingMargin from "@/views/cards/LineChartOperatingMargin.vue";
import LineChartProfitBeforeTaxMargin from "@/views/cards/LineChartProfitBeforeTaxMargin.vue";
import LineChartReturnOnCapitalReturned from "@/views/cards/LineChartReturnOnCapitalReturned.vue";
import LineChartAcidTest from "@/views/cards/LineChartAcidTest.vue";

export default {
    name: "DashFinance",

    components: {
        FinanceDropdown,
        LineChartProfitPerEmployee,
        LineChartAssetTurnover,
        LineChartCashflow,
        LineChartRevenuePerEmployee,
        LineChartGrossMargin,
        LineChartOperatingMargin,
        LineChartProfitBeforeTaxMargin,
        LineChartReturnOnCapitalReturned,
        LineChartAcidTest,
    },

    data() {
        return {
            activeTab: 'tab1',
            companies: '',
            resultID: [],
            userData: '',
            averageData: '',
            averageDataSector: '',
            yLabels: ['2020, 2021, 2022'], // Update to be an Array
            loading: false,
        };
    },

    created() {
        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/financial'
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: companies
                }, {
                    data: graphData
                }, ]) => {
                    this.companies = companies;
                    // user data
                    this.userData = this.companies.advancedDetails;
                    // averageData GLOBAL
                    this.averageData = graphData.global.advancedDetails

                    // console.log(this.averageData)
                    //  averageData Sector
                    this.averageDataSector = graphData.sector.advancedDetails
                    this.loading = true;
                });
        });
    },

    computed: {

    },

    methods: {

        updateChart() {
            // Update the chart in the child component
            this.$refs.childComponent.updateChart();
        },

    },

};
</script>
<style scoped>

.tab-buttons {
    margin-bottom: -55px;
}

.active {
    color: black!important;
}
</style>