<template>
  <div class="relative h-screen w-full flex items-center">
    <div class="absolute top-50px left-100px">
      <a
        class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        href="/"
      >
        <blacklogo class="" />
      </a>
    </div>
    <div class="container flex flex-col items-center">
      <div class="lg:w-full">
       <h1 class="font-medium text-2xl text-center">Forgotten Password?</h1>
      </div>
        <div class="bg-white lg:w-full md:6/12 w-10/12 my-auto mr-auto">
          <form @submit.prevent="sendEmail" class="mt-6">

            <div class="flex w-full">
              <div class="w-half mr-50px">
                <div class="my-5 text-sm">
                  <label for="email" class="block text-black">Email</label>
                  <input
                    type="text"
                    v-model="email"
                    @blur="$v.email.$touch"
                    id="email"
                    class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full"
                    placeholder="Email"
                    :class="{ 'border-1 border-red-500': $v.email.$error }"
                  />
                  <div
                    v-if="!$v.email.required && $v.email.$error"
                    class="text-red-500"
                  >
                    The email field is required
                  </div>
                  <div
                    v-else-if="!$v.email.email && $v.email.$error"
                    class="text-red-500"
                  >
                    The email field is invalid
                  </div>
                </div>
              </div>
            </div>

            <div v-if="errorMessage" class="mt-2 text-red-500 mb-2">
              {{ errorMessage }}
            </div>
            <div v-else class="mt-2 mb-2">
                {{ succsessMessage }}
            </div>
            <button
              class="flex items-center justify-center relative text-white p-3 duration-300 rounded-full lg:w-3/12 mr-50px btn--arrow btn--arrow-white"
              :class="$v.$error ? 'bg-teal text-white-800 cursor-default' : 'bg-teal hover:bg-black'"
              :disabled="$v.$error"
            >
              <loading v-if="false" class="w-4 h-4 fill-current animate-spin duration-75" />
              <span class="ml-4">Submit</span>
            </button>
          </form>
        </div>
    </div>
  </div>
  </template>
  
  <script>
import blacklogo from '@/assets/svg/blacklogo.svg';
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import firebase from "firebase";
  
  export default {
    name: "forgotPassword",

    components: {
        blacklogo
    },

    data() {
        return {
            email: "",
            errorMessage: "",
            succsessMessage: "",
            emailSending: false,
        };
    },
    

    validations: {
        email: {
            required,
            email,
            minLength: minLength(3),
        },
    },
    
    methods: {
        sendEmail() {
        if (!this.email) {
            this.errorMessage = "Please type in a valid email address.";
            return;
        } else {
            this.succsessMessage = "An email has been sent to the provided email address."
        }
        this.errorMessage = null;
        this.emailSending = true;
        firebase
            .auth()
            .sendPasswordResetEmail(this.email)
            .then(() => {
            this.emailSending = false;
            })
            .catch(error => {
            this.emailSending = false;
            this.errorMessage = error.message;
            });
        }
    },
  
 
  };
  </script>
  