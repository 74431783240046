<template>
<div id="financepreformance" class="dashboard-questions bg-greyblue pb-100px">
    <div class="container" v-if="loading == true">
        <div class="flex items-center px-25px py-25px bg-black text-white rounded">
            <div class="lg:w-3/12">
                <p class="text-med">Financial Reviews</p>
            </div>
            <!-- <div class="lg:w-3/12 lg:ml-auto">
                <FinanceDropdown />
            </div> -->
        </div>
        <div class="flex flex-wrap items-center py-50px mt-50px rounded bg-white">
            <div class="w-full lg:w-half mb-12 xl:mb-0 px-4">
                <LineChartRevenuePerEmployee :averageData="averageData" :userData="userData" :yLabels="yLabels" />
            </div>
            <div class="w-full lg:w-half mb-12 xl:mb-0 px-4">
                <LineChartProfitPerEmployee :averageData="averageData" :userData="userData" :yLabels="yLabels" />
            </div>
            <div class="w-full xl:w-full mt-12 mb-12 xl:mb-0 px-4">
                <LineChartAssetTurnover :averageData="averageData" :userData="userData" :yLabels="yLabels" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import axios from "axios";
import firebase from "firebase";
import store from "./../../store";
import FinanceDropdown from "@/views/dropdowns/FinanceDropdown.vue";
import LineChartProfitPerEmployee from "@/views/cards/LineChartProfitPerEmployee.vue";
import LineChartAssetTurnover from "@/views/cards/LineChartAssetTurnover.vue";
import LineChartCashflow from "@/views/cards/LineChartCashflow.vue";
import LineChartRevenuePerEmployee from "@/views/cards/LineChartRevenuePerEmployee.vue";

export default {
    name: "DashFinance",

    components: {
        FinanceDropdown,
        LineChartProfitPerEmployee,
        LineChartAssetTurnover,
        LineChartCashflow,
        LineChartRevenuePerEmployee,
    },

    data() {
        return {
            companies: '',
            resultID: [],
            userData: '',
            averageData: '',
            averageDataSector: '',
            yLabels: ['2020, 2021, 2022'], // Update to be an Array
            loading: false,
        };
    },

    created() {
        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/financial'
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: companies
                }, {
                    data: graphData
                }, ]) => {
                    this.companies = companies;
                    // user data
                    this.userData = this.companies.advancedDetails;
                    // averageData GLOBAL
                    this.averageData = graphData.global.advancedDetails

                    // console.log(this.averageData)
                    //  averageData Sector
                    this.averageDataSector = graphData.sector.advancedDetails
                    this.loading = true;
                });
        });
    },

    computed: {

    },

    methods: {

        updateChart() {
            // Update the chart in the child component
            this.$refs.childComponent.updateChart();
        },

    },

};
</script>
