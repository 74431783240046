<template>
<div class="dashboard-questions bg-greyblue">
    <div class="container">
        <div class="flex items-center py-150px">
            <div class="lg:w-half">
                <div class="bg-darkgreen text-white rounded py-100px px-50px">
                    <p class="text-xLarge mb-50px text-darkgreen-800">Your PDAI<br>performance<br>review</p>
                    <p class="text-small mb-150px">Each section takes up to <b>7 minutes</b> and can be<br>completed later or all in one. The four sections of<br> our review cover <b>leadership, technology, growth<br>and barriers. </b></p>
                    <router-link class="btn btn--arrow btn--long bg-white px-6 py-4 rounded-full mr-50px duration-300 text-darkgreen hover:bg-black hover:text-white" to="/questionnaire">
                        Continue your review
                    </router-link>
                </div>
            </div>
            <div class="lg:w-half flex flex-col ml-50px">
                <div class="flex items-center justify-center py-10px px-25px my-25px bg-white text-dark rounded-full">
                    <greycircle class="mr-25px" />
                    <p class="text-darkblue-800 mr-50px">Leadership</p>
                    <span class="dashboard-questions__separator"> </span>
                    <router-link class="btn btn--arrow bg-white px-6 py-4 mr-50px duration-300 text-darkgreen" to="/leadershipintro">
                        Continue your review
                    </router-link>
                    <div v-for="(percentComplete, index) in firstFourPercentComplete" :key="index">
                        <div class="relative" v-if="index === 0">
                            <percentageCircle :percentage="percentComplete" />
                            <p class="percentage-number">{{ percentComplete }}</p>
                        </div>
                    </div>

                </div>
                <div class="flex items-center justify-center py-10px px-25px my-25px bg-white text-dark rounded-full">
                    <greycircle class="mr-25px" />
                    <p class="text-darkblue-800 mr-50px">Technology</p>
                    <span class="dashboard-questions__separator"> </span>
                    <router-link class="btn btn--arrow bg-white px-6 py-4 mr-50px duration-300 text-darkgreen" to="/technologyintro">
                        Continue your review
                    </router-link>
                    <div v-for="(percentComplete, index) in firstFourPercentComplete" :key="index">
                        <div class="relative" v-if="index === 1">
                            <percentageCircle :percentage="percentComplete" />
                            <p class="percentage-number">{{ percentComplete }}</p>
                        </div>
                    </div>

                </div>
                <div class="flex items-center justify-center py-10px px-25px my-25px bg-white text-dark rounded-full">
                    <greycircle class="mr-25px" />
                    <p class="text-darkblue-800 mr-85px">Growth</p>
                    <span class="dashboard-questions__separator"> </span>
                    <router-link class="btn btn--arrow bg-white px-6 py-4 mr-50px duration-300 text-darkgreen" to="/growthintro">
                        Continue your review
                    </router-link>
                    <div v-for="(percentComplete, index) in firstFourPercentComplete" :key="index">
                        <div class="relative" v-if="index === 2">
                            <percentageCircle :percentage="percentComplete" />
                            <p class="percentage-number">{{ percentComplete }}</p>
                        </div>
                    </div>

                </div>
                <div class="flex items-center justify-center py-10px px-25px my-25px bg-white text-dark rounded-full">
                    <greycircle class="mr-25px" />
                    <p class="text-darkblue-800 mr-85px">Barriers</p>
                    <span class="dashboard-questions__separator"> </span>
                    <router-link class="btn btn--arrow bg-white px-6 py-4 mr-50px duration-300 text-darkgreen" to="/barriersintro">
                        Continue your review
                    </router-link>
                    <div v-for="(percentComplete, index) in firstFourPercentComplete" :key="index">
                        <div class="relative" v-if="index === 3">
                            <percentageCircle :percentage="percentComplete" />
                            <p class="percentage-number">{{ percentComplete }}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import store from "../../store";
import greycircle from '@/assets/svg/greycircle.svg';
import percentageCircle from '../molecules/percentageCircle.vue'

export default {
    name: "DashQuestions",

    components: {
        percentageCircle,
        greycircle
    },

    created() {
        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/1',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/2',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/3',
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/4'
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: companies
                }, {
                    data: multipleResults
                }, ]) => {
                    this.companies = companies;
                    this.multipleResults = multipleResults;
                });

        });
    },

    data() {
        return {
            companies: [],
            results: [],
            multipleResults: '',
            leadershipPercentage: '',
        }
    },

    computed: {
        firstFourPercentComplete() {
            if (this.companies && this.companies.results) {
                const percentCompleteArray = [];
                this.companies.results.forEach(result => {
                    if (result.section) {
                        result.section.forEach(section => {
                            if (section.percentComplete) {
                                // Round the decimal value to 0 decimal places and convert to integer
                                const percentComplete = parseInt(section.percentComplete.toFixed(0));
                                percentCompleteArray.push(percentComplete);
                            }
                        });
                    }
                });

                return percentCompleteArray.slice(0, 4);
            } else {
                return [];
            }
        }

    }

};
</script>
<style scoped>
.percentage-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: 600;
    color: #2D3748;
    z-index: 3;
}
</style>