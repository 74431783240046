<template>
  <div>
    <a
      class="lg:text-black lg:bg-white rounded lg:hover:text-blueGray-200 text-blueGray px-3 py-4 flex items-center text-xs"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      Sector Productivity
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        to="/admin/dashboard"
        class="text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black"
      >
       Sector Productivity
      </router-link>
      <router-link
        to="/admin/settings"
        class="text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black"
      >
        Sector Productivity
      </router-link>
      <router-link
        to="/admin/tables"
        class="text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black"
      >
       Sector Productivity
      </router-link>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
