<template>
<div>
    <navbar />
    <main>
        <ReviewPreformance />
        <ReviewTabbed />
        <peer-table class="pb-8"/>
        <Footer />
    </main>
</div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import firebase from "firebase";
import store from "../store";
import Navbar from "@/views/navs/ReviewNavbar.vue";
import ReviewPreformance from "@/views/organisms/ReviewPreformance.vue";
import ReviewTabbed from "@/views/organisms/ReviewTabbed.vue";
import PeerTable from "./tables/PeerTable.vue";
import Footer from "@/views/footers/Footer.vue";

export default {
    name: "DetailedView",

    components: {
        Navbar,
        ReviewPreformance,
        ReviewTabbed,
        PeerTable,
        Footer
    },

    data() {
        return {
            finInfo: [],
        }
    },

    created() {

        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/financial',
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: finInfo
                }]) => {
                    this.finInfo = finInfo;
                });

            // console.log(this.finInfo)

        });

    },

    methods: {
        signOut() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$router.replace("/");
                })
                .catch((error) => console.log(error.message));
        },

        
    },
};
</script>
<style scoped>
.nav-button {
    color: black!important;
}
</style>