<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          to="/"
        >
          <logo class="" />
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <router-link
              class="block text-center text-white p-3 duration-300 w-full nav-button"
              to="/companyinfo"
            >
             Edit User Details
            </router-link>
          </li>
          <li class="flex items-center">
            <button
            class="block text-center text-white p-3 duration-300 w-full nav-button"
            @click="signOut"
            >
            Log Out
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import logo from '@/assets/svg/logo.svg';
import firebase from "firebase";

export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/");
        })
        .catch((error) => console.log(error.message));
    },
  },
  components: {
    logo
  },
};
</script>
