<!-- Circle.vue (Reusable Vue.js component) -->
<template>
    <div class="circle">
      <div class="fill" :style="{ height: fillHeight }"></div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      percentage: {
        type: Number,
        required: true,
      },
    },
    computed: {
      fillHeight() {
        return `${this.percentage}%`;
      },
    },
  };
  </script>
  
  <style scoped>
  .circle {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ccc;
    overflow: hidden;
  }

  .circle:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #fff;
    z-index: 2;
  }
  
  .fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #41ACAA;
    transform-origin: top;
    transform: scaleY(0);
    animation: fillAnimation 2s linear forwards;
  }
  
  @keyframes fillAnimation {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  </style>
  