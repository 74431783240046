<template>
<div>
    <navbar />
    <main>
        <section class="hero relative pt-24 md:mt-0 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-12 md:px-12 px-4 bg-darkgreen">
            <div class="lg:w-half mr-50px">
                <heroImage class="hero__image" />
            </div>
            <div class="lg:w-half lg:ml-100px md:flex-1 md:mr-10 lg:block flex flex-col items-center mb-50px">
                <h1 class="text-xxLarge font-medium mb-7 text-white">
                    Discover how your<br>business compares<br>with your peers.
                </h1>
                <p class="font-regular mb-7 text-white">
                    Our free peer to peer comparison tool reveals how<br>
                    your business is performing against your competitors.<br>
                    This tool is designed to support executive management<br>
                    in decision making surrounding technology.<br>
                </p>
                <div class="flex items-center font-poppins mt-36">
                    <router-link to="/preformancereview" v-scroll-to="'#dashpreformance'" class="btn btn--arrow bg-white px-6 py-4 rounded-full mr-50px duration-300 text-darkgreen hover:bg-black hover:text-white">
                        <span>Preformance Reviews</span>
                    </router-link>
                    <router-link to="/preformancereview" v-scroll-to="'#financepreformance'" class="btn btn--arrow btn--arrow-white btn--outline-white text-white px-6 py-4 rounded-full ml-500px duration-300 hover:bg-black">
                        <span>Financial Reviews</span>
                    </router-link>
                </div>
            </div>
            <div class="flex flex-wrap">
            </div>
        </section>
        <DashQuestions :loading="loading" />
        <DashPreformance :loading="loading" />
        <DashFinance :loading="loading" />
        <Footer />
    </main>
</div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import firebase from "firebase";
import store from "../store";
import heroImage from "@/assets/svg/hero.svg";
import Navbar from "@/views/navs/AuthNavbar.vue";
import DashQuestions from "@/views/organisms/DashQuestions.vue";
import DashPreformance from "@/views/organisms/DashPreformance.vue";
import DashFinance from "@/views/organisms/DashFinance.vue";
import Footer from "@/views/footers/Footer.vue";

export default {
    name: "Dashboard",

    components: {
        heroImage,
        Navbar,
        DashQuestions,
        DashPreformance,
        DashFinance,
        Footer
    },

    data() {
        return {
            finInfo: [],
            loading: false,
        }
    },

    created() {

        firebase.auth().onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);

            axios.defaults.headers = {
                'Content-Type': 'application/json',
                Authorization: user.za
            }

            let endpoints = [
                'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/financial',
            ];

            Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                .then(([{
                    data: finInfo
                }]) => {
                    this.finInfo = finInfo;
                });

            // console.log(this.finInfo)

        });

    },

    methods: {
        signOut() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$router.replace("/");
                })
                .catch((error) => console.log(error.message));
        },

        
    },
};
</script>

